<template>
  <div class="view pa24">
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column type="selection" width="50"/>
        <el-table-column prop="name" align="center" label="动态标题" show-overflow-tooltip/>
        <el-table-column prop="url" align="center" label="发布人" show-overflow-tooltip/>
        <el-table-column prop="url" align="center" label="发布时间" show-overflow-tooltip/>
        <el-table-column prop="url" align="center" label="所属商会" show-overflow-tooltip/>
        <el-table-column prop="url" align="center" label="动态类型" show-overflow-tooltip/>
        <el-table-column prop="url" align="center" label="红包类型" show-overflow-tooltip/>
        <el-table-column prop="url" align="center" label="红包金额" show-overflow-tooltip/>
        <el-table-column prop="url" align="center" label="已抢/剩余" show-overflow-tooltip/>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
            >
              红包记录</el-button
            >
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="红包记录"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
    >
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button @click="centerDialogVisible = false">取 消</el-button>-->
<!--        <el-button type="primary" @click="determine" v-if="bdetermine"-->
<!--        >确 定</el-button-->
<!--        >-->
<!--      </span>-->
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {addChart,selectChartList,upChart,delChart} from "@/api/square";
let ac;
export default {
  name:'redEnvelope',
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      jurisdictionA:'',
      jurisdictionB:'',
      chartId:'',
      centerDialogVisible:false
    };
  },
  components: {
    commonTable
  },
  computed: {
    ...mapState(['routerArr']),
    uploadDisabled() {
      return this.ruleForm.coverImg !== "";
    },
  },
  watch:{
    routerArr(val){
      console.log(val)
      this.jurisdictionA = val.includes('080')
      this.jurisdictionB = val.includes('081')
      if(this.jurisdictionA || this.jurisdictionB){
        this.queryPage()
      }
    }
  },
  mounted(){
    this.queryPage();
  },

  methods: {
    async queryPage() {
      console.log('00000000000')
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        type:0
      };
      try {
        this.loading = true;
        const result = await selectChartList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          if (list[i].status == 0) {
            list[i].status = true
          }else {
            list[i].status = false
          }
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.coverImg = "";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if(this.isUpdate){
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;

    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.isUpdate = true;
      this.ruleForm.url = `https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/${res.data}`;
    },
    beforeAvatarUploadVideo(file) {
      const isLt2M = file.size / 1024 / 1024 < 30;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 30MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccessCoverImg(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = `https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/${res.data}`;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm.title = data.name;
      this.ruleForm.sort = data.orderBy;
      this.ruleForm.isState = data.status?0:1;
      this.ruleForm.link = data.url;
      this.ruleForm.coverImg = data.img;
      this.fileList = [{ url: data.img, uid: 1 }];
      this.bdetermine = b;
      this.bEdit = b;
      this.chartId = data.chartId
    },
    /**@method 添加视频 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              type:0,
              name:this.ruleForm.title,
              img:this.ruleForm.coverImg,
              orderBy:this.ruleForm.sort,
              status:this.ruleForm.isState,
              url:this.ruleForm.link
            };
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              data.chartId = this.chartId
              await upChart(data);
            } else {
              await addChart(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delChart({ chartId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    async saveSort(row){
      if(row && row.sort != ""){
        console.log(row)
        const result = await upChart(row);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    async saveStatus(row){
      if(row){
        console.log(row)
        row.status = row.status?0:1
        const result = await upChart(row);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        sort: "",
        isState: 0,
        link:'',
        coverImg:'',
      }),
          this.fileList = []
      this.centerDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  //line-height: 175px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>




